import React, {useEffect}from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import Footer from './shared/components/Footer/Footer'
import Home from './Home/pages/Home';
import Header from './shared/components/Header/Header';
import CancelService from './CancelService/pages/CancelService';
import TermsOfService from './TermsService/pages/TermsOfService';
import PrivacyPolicy from './PrivacyPolicy/pages/PrivacyPolicy';
import Checkout from './Checkout/pages/Checkout';
import RefundPolicy from './RefundPolicy/pages/RefundPolicy';
import haveAccess from './utils/helpers/validators/routeValidation';
import NotFound from './NotFound/page/NotFound';
import FloatingCancelButton from './shared/components/FloatingCancelButton/FloatingCancelButton';

localStorage.clear();

function App() {
    useEffect(() => {
      const Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src=process.env.REACT_APP_TAWK_PROPERTY;
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
    }, []);
  return ( 
    <Router>
      <Header />
      <FloatingCancelButton />
        <main>
        <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>

        <Route path="/refund-policy" exact>
          <RefundPolicy />
        </Route>

        <Route path="/cancel-service" exact>
          <CancelService />
        </Route>

          <Route path="/checkout" 
          render={() =>
            haveAccess() ? (
              <Checkout />
            ) : (
              <Redirect to="/" />
            )
          }
          exact>
           
        </Route>
        

        <Route path="/terms-of-service" exact>
          <TermsOfService />
        </Route>

        <Route path="**">
          <NotFound/>
        </Route>

        

      </Switch>
        </main>
        <Footer />
    </Router>
  );
}


export default App;
